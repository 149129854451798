import React from "react";

import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

import Image from "../assets/images/404.png";

const NotFoundPage = () => (
  <Layout>
    <SEO
      seo={{
        metaTitle: "404: Not found",
        metaDescription: "It looks like you got lost",
      }}
    />
    <div className="container">
      <div className="row">
        <img
          src={Image}
          alt=""
          style={{ display: "flex", paddingTop: "150px", margin: "auto" }}
        />
      </div>
      <div className="row">
        <div className="grey-background" style={{ borderRadius: "50px", padding: "75px", width: "500px", margin: "auto" }}>
          <Link to={"/"} style={{display: "flex"}}>
            <button className="violet-btn btn join-btn" style={{margin: "auto"}}>Powrót na stronę główna</button>
          </Link>
        </div>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
